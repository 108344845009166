import { createContext, useContext, useEffect, useState } from "react";
import contacts from "data/contacts";
import axios from "axios";
import env from "config";
import { useParams } from "react-router-dom";
import Toast from "components/Toast";
import SsoHandler from "utils/SSOhandler";

const UsersContext = createContext();

const useUsersContext = () => useContext(UsersContext);
const BASE_URL = `${env.API_URL}/v1`;
const CHAT_API = `${env.Chat_API}`;
const CHAT_AUTH = `${env.Chat_Auth}`;

const UsersProvider = ({ children }) => {
  const { SSO, checkSSO } = SsoHandler();
  // const params = useParams();

  // const socket = useSocketContext();
  const [isActiveSide, setIsActiveSide] = useState(true);
  const { data, processing, handleRefreshData } = contacts(SSO);
  const [users, setUsers] = useState(data);
  const [userTyping, setUserTyping] = useState({});
  const [fromPromptMsg, setFromPromptMsg] = useState({});
  const [locationUsers, setLocationusers] = useState();
  const [agencyConfig, setAgencyConfig] = useState({});

  const [showExploreContent, setShowExploreContent] = useState(false);

  const getLocationUsers = async (SSO_data) => {
    const response = await axios.get(
      `${BASE_URL}/user/chatusers?locationId=${SSO_data?.id}`
    );
    console.log("chatusersssssss", response);
    if (response) {
      setLocationusers(response?.data?.data);
    }
  };

  const getAgencyConfig = async (SSO_data) => {
    console.log('agencyConfig',SSO_data);
    const response = await axios.get(
      `${BASE_URL}/agency/agencyconfig?_id=${SSO_data?.agency_id}`
    );
    
    if (response) {
      setAgencyConfig(response?.data?.data);
    }
  }

  // Function to toggle showExploreContent
  const enableExplore = () => {
    setShowExploreContent(true);
  };

  const enableChat = () => {
    setShowExploreContent(false);
  };

  const [locValid, setlocValid] = useState(data);

  const [toast, setToast] = useState({ message: "", isVisible: false });

  const showToast = (message) => {
    setToast({ message, isVisible: true });
    setTimeout(() => setToast({ message: "", isVisible: false }), 3000); // Auto-hide after 3 seconds
  };

  const hideToast = () => {
    setToast({ message: "", isVisible: false });
  };

  const hideLeftSidebar = () => {
    setIsActiveSide(false);
  };

  const showLeftSidebar = () => {
    setIsActiveSide(true);
  };

  const handleDeleteContact = async (contactId) => {
    const response = await axios.get(`${BASE_URL}/chat/delete?id=${contactId}`);
    return response;
  };

  const _updateUserProp = (userId, prop, value) => {
    setUsers((users) => {
      const usersCopy = [...users];
      let userIndex = users.findIndex((user) => user.id === userId);
      const userObject = usersCopy[userIndex];
      usersCopy[userIndex] = { ...userObject, [prop]: value };
      setUserTyping(usersCopy);
      return usersCopy;
    });
  };

  const setUserAsTyping = (data) => {
    const { userId } = data;
    setUserTyping(true);
    _updateUserProp(userId, "typing", true);
  };
  const setUserAsNotTyping = (data) => {
    const { userId } = data;
    setUserTyping(false);
    _updateUserProp(userId, "typing", false);
  };

  const setOnFromPrompt = (userId) => {
    setFromPromptMsg(true);
    _updateUserProp(userId, "fromPrompt", true);
  };

  const setOffFromPrompt = (data) => {
    const { userId } = data;
    setFromPromptMsg(false);
    _updateUserProp(userId, "fromPrompt", false);
  };
  const fetchMessageResponse = async (data) => {
    // setUsers((users) => {
    const { userId, response } = data;
    setUserAsTyping(data);
    let msg_data = JSON.stringify({
      conversation_id: userId,
      // profile_id: SSO?.id,
      profile_id: 'emodemo',
      // user_name: SSO?.first_name,
      user_name: 'Mafe',
      prompt: response.content,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://pandora-bqjn475d2a-nw.a.run.app/api/chat/message`,
      // url: `${CHAT_API}/chat/message`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer a71860da-1a58-4797-90f1-02dcb5fd06b1`,
        // Authorization: `Bearer ${CHAT_AUTH}`,
        model: 'openai/gpt-3.5-turbo' 
      },
      data: msg_data,
    };
    var msg = "";
    await axios
      .request(config)
      .then((response) => {
        msg = response.data.data;
        setUserAsNotTyping(data);
      })
      .catch(async (error) => {
        if (error.response?.status == 400) {
          await axios
            .get(BASE_URL + "/chat/delete?id=" + userId)
            .then(async function (response) {
              window.location.href = `/`;
            })
            .catch(function (error) { });
          // window.location.reload();

          // alert("error");
        }
      });

    let userIndex = users.findIndex((user) => user.id === userId);
    const usersCopy = JSON.parse(JSON.stringify(users));
    const newMsgObject = {
      content: msg,
      sender: userId,
      time: new Date().toLocaleTimeString(),
      status: null,
    };
    const payload = {
      message: newMsgObject,
      conversation_id: userId,
    };
    await axios
      .post(BASE_URL + "/message/response", { ...payload })
      .then(async function (response) { })
      .catch(function (error) { });
    var key = Object.keys(usersCopy[userIndex].messages);
    key = key[0];
    usersCopy[userIndex].messages[key].push(newMsgObject);
    setUsers(usersCopy);
    // return usersCopy;
    // });
  };
  const refresh = (newdata) => {
    // setUsers(newdata);

    users.unshift(newdata);
    setUsers(users);
  };
  const checkLocation = async () => {
    // if (loc_id === "settings") {
    //   return false;
    // }
    console.log('dffdfdf',SSO?.id)
    // alert(SSO?.id);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://3yv7611xa1.execute-api.eu-west-2.amazonaws.com/Prod/haumea/api/profiles/${SSO?.id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setlocValid(true);
      })
      .catch((error) => {
        window.location.href = `https://connect.maybetech.com/?profile_id=${SSO?.id}&profile_name=${SSO?.email}&username=${SSO?.first_name}&type=chat`;
        setlocValid(false);
        // setlocValid(true);
      });
  };

  // const CreateUser = async (SSO_data) => {
  //   return await axios
  //     .post(BASE_URL + "/chat/createuser", SSO_data)
  //     .then(async function (response) {
  //       return response.data.data;
  //     })
  //     .catch(function (error) {
  //       return error;
  //     });
  // };
  const onLoad = (SSO) => {
    if (SSO !== "" && SSO !== undefined) {
      setUsers(data);
      checkLocation();
      // CreateUser(SSO);
      getLocationUsers(SSO);
      getAgencyConfig(SSO);
    }
  };
  useEffect(() => {
    onLoad(SSO);
  }, [data, SSO]);
  useEffect(() => {
    checkSSO();
  }, []);

  const setUserAsUnread = (userId) => {
    _updateUserProp(userId, "unread", 0);
  };

  const addNewMessage = async (userId, message, prompt) => {
    let userIndex = users.findIndex((user) => user.id === userId);
    var usersCopy = [...users];
    console.log("usersCopyusersCopyusersCopyusersCopy");
    console.log(userId, userIndex);
    const newMsgObject = {
      content: message,
      sender: null,
      time: new Date().toLocaleTimeString(),
      status: "delivered",
    };
    var key = Object.keys(usersCopy[userIndex].messages);
    console.log(key);
    key = key[0];
    usersCopy[userIndex].messages[key].push(newMsgObject);
    const payload = {
      message: newMsgObject,
      conversation_id: userId,
    };

    await axios
      .post(BASE_URL + "/message", { ...payload })
      .then(async function (response) {
        if (response.data.chatupdated) {
          usersCopy[userIndex].name = message;
          setUsers(usersCopy);
        } else {
          setUsers(usersCopy);
        }
        if (prompt != undefined) {
          fetchMessageResponse({
            userId: userId,
            response: { content: prompt },
          });
        } else {
          fetchMessageResponse({
            userId: userId,
            response: { content: message },
          });
        }
      })
      .catch(function (error) { });
  };

  return (
    <UsersContext.Provider
      value={{
        users,
        SSO,
        data,
        setUsers,
        locValid,
        setlocValid,
        setUserAsUnread,
        addNewMessage,
        processing,
        refresh,
        userTyping,
        hideLeftSidebar,
        showLeftSidebar,
        isActiveSide,
        handleDeleteContact,
        toast,
        showToast,
        hideToast,
        showExploreContent,
        enableExplore,
        enableChat,
        setOnFromPrompt,
        setOffFromPrompt,
        fromPromptMsg,
        getLocationUsers,
        locationUsers,
        handleRefreshData,
        agencyConfig,
      }}
    >
      {children}
      {toast.isVisible && <Toast message={toast.message} onClose={hideToast} />}
    </UsersContext.Provider>
  );
};

export { useUsersContext, UsersProvider };
